import HubspotForm from "react-hubspot-form"
import React from "react"
import joinImg from "../images/kv-network.png"
import resourceIcon from "../images/icon-resource.svg"
import connectIcon from "../images/icon-connect.svg"

import Layout, { TopCTAStateContext } from "../components/layout"

const JoinPage = () => {
  const cardData = [
    {
      key: "resource",
      role: "As a social worker...",
      icon: resourceIcon,
      text:
        "Join our private networking forum where you can collaborate with other subject matter experts and build relationships with community organizations to prioritize your referrals.",
    },
    {
      key: "connect",
      role: "As a CBO worker...",
      icon: connectIcon,
      text:
        "Expand your outreach by joining our network to engage new patient populations and receive updates about new policy and grant programs from other healthcare leaders.",
    },
  ]

  const cards = cardData.map(join => {
    const { key, role, icon, text } = join

    return (
      <div key={key}>
        <p className="body-text">{role}</p>
        <hr />
        <div className="join-card">
          <img src={icon} alt="Join Icon" />
          <h4>{text}</h4>
        </div>
        <hr />
      </div>
    )
  })

  return (
    <Layout>
      <img src={joinImg} alt="Join Image" className="banner-img" />
      <section className="join">
        <h1>Join Our Network</h1>
        <div className="hubspot-form">
          <HubspotForm
            className="join-form"
            portalId="4891903"
            formId="069019c6-2433-4d75-9914-36f8770dd226"
          />
        </div>
        <div className="join-text">
          <p className="paragraph-text">
            We encourage community based organizations and social workers to
            join our managed network of community resources and healthcare
            professionals. The Network is a central resource for addressing the
            social needs of patients to make it easier to close the loop on
            cases and ensure the needs of patients are met.
                  </p>
          {cards}
        </div>
      </section>
    </Layout>
  )
}

export default JoinPage
